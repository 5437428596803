<template>
  <StitchDialog
    :class="['detail-item']"
    :visible.sync="showDialog"
    :center="isLoadingItem"
    @close="onClose"
  >
    <template v-if="item">
      <h1
        slot="title"
        class="detail-item__title"
      >
        {{ item.name }} <br>
      </h1>
      <div class="detail-item__content">
        <!-- ------------------------- -->
        <!-- IMAGE PREVIEW -->
        <!-- ------------------------- -->
        <div class="detail-item__content-image">
          <div
            v-if="item.libraryType === LIBRARY_TYPE.FABRIC"
            class="detail-item__content-image--fabric-border"
          />

          <img
            v-if="itemPreviewUrl"
            :class="{
              'content-image-fabric': item.libraryType === LIBRARY_TYPE.FABRIC
            }"
            :src="itemPreviewUrl"
            alt="preview image"
          >
          <img
            v-else
            :src="placeholder"
            alt="preview image"
            crossorigin="use-credentials"
          >
        </div>
        <!-- ------------------------- -->
        <!-- INFOS -->
        <!-- ------------------------- -->
        <div class="detail-item__content-infos">
          <ElementTag
            v-if="!hasModel3D"
            type="warning"
            size="mini"
          >
            The 3D File is missing
          </ElementTag>
          <ElementTag
            v-for="(value, index) in info"
            :key="index"
            :type="value.rate === 0 ? 'danger' : 'info'"
            size="mini"
          >
            <span v-if="!value.text">{{ value }}</span>
            <span v-else>{{ value.text }}</span>
          </ElementTag>
        </div>
        <!-- ------------------------- -->
        <!-- ACTIONS -->
        <!-- ------------------------- -->
        <!-- TODO export all those actions in a component -->
        <div class="detail-item__content-actions">
          <ElementDropdown
            v-if="!browzwearAPI"
            @command="triggerDropdownCallback"
          >
            <ElementButton>Edit {{ item.libraryType }}</ElementButton>
            <ElementDropdownMenu
              slot="dropdown"
              :append-to-body="false"
            >
              <ElementDropdownItem
                :command="toggleLibraryItemCreateForm(false)"
              >
                Edit{{ !isTrim ? ' Info' : '' }}
              </ElementDropdownItem>
              <ElementDropdownItem
                v-if="!isTrim"
                :command="toggleLibraryItemCreateForm(true)"
              >
                Upload New File
              </ElementDropdownItem>
              <ElementDropdownItem
                divided
                class="detail-item__content-actions-menu-item detail-item__content-actions-menu-item-delete"
                :command="openConfirmDelete"
              >
                Delete
              </ElementDropdownItem>
            </ElementDropdownMenu>
          </ElementDropdown>

          <!-- -------------------------------------- -->
          <!-- Button to download on Browzwear Plugin -->
          <!-- -------------------------------------- -->
          <ElementButton
            v-if="browzwearAPI && !isTrim"
            type="primary"
            plain
            class="detail-item__action-download"
            :disabled="!hasModel3D"
            @click="sendItemToPlugin({ item })"
          >
            Download {{ item.libraryType }}
          </ElementButton>

          <ElementDropdown
            v-else-if="browzwearAPI && isTrim"
            @command="sendItemToPlugin"
          >
            <ElementButton
              :disabled="!hasModel3D"
              type="primary"
              plain
              class="detail-item__action-download"
            >
              Download {{ item.libraryType }}
            </ElementButton>
            <ElementDropdownMenu
              slot="dropdown"
              :append-to-body="false"
            >
              <ElementDropdownItem
                v-for="(value, index) in item.filesDict.u3ma"
                :key="index"
                :command="{ item, index }"
              >
                {{ value.description }}
              </ElementDropdownItem>
            </ElementDropdownMenu>
          </ElementDropdown>

          <!-- Normal view -->
          <!-- Button -->
          <!-- ----------- -->
          <ElementButton
            v-else-if="item.libraryType !== LIBRARY_TYPE.FABRIC && !isTrim"
            type="primary"
            plain
            :disabled="!hasModel3D"
            class="detail-item__action-download"
            @click="
              downloadAsset({ url: item.filesDict.model3D[0].assets_url, item })
            "
          >
            Download {{ item.libraryType }}
          </ElementButton>

          <!-- FABRICS Buttons -->
          <ElementDropdown
            v-else-if="item.libraryType === LIBRARY_TYPE.FABRIC"
            @command="downloadAsset"
          >
            <ElementButton
              :disabled="!hasModel3D"
              type="primary"
              plain
              class="detail-item__action-download"
            >
              Download {{ item.libraryType }}
            </ElementButton>
            <ElementDropdownMenu
              slot="dropdown"
              :append-to-body="false"
            >
              <ElementDropdownItem
                :disabled="!hasModel3D"
                :command="
                  hasModel3D
                    ? {
                      url: item.filesDict.model3D[0].assets_url,
                      item
                    }
                    : null
                "
              >
                File
              </ElementDropdownItem>
              <ElementDropdownItem
                :disabled="!hasThumbnail"
                :command="
                  hasThumbnail
                    ? {
                      url: item.filesDict.thumbnail[0].assets_url,
                      item,
                      assetType: 'thumbnail'
                    }
                    : null
                "
              >
                Thumbnail
              </ElementDropdownItem>
            </ElementDropdownMenu>
          </ElementDropdown>

          <!-- TRIMS Buttons -->
          <ElementButton
            v-else-if="isTrim && item.filesDict.model3D.length === 1"
            type="primary"
            plain
            :disabled="!hasModel3D"
            class="detail-item__action-download"
            @click="
              downloadAsset({ url: item.filesDict.model3D[0].assets_url, item })
            "
          >
            Download {{ item.libraryType }}
          </ElementButton>

          <ElementDropdown
            v-else-if="isTrim"
            @command="downloadAsset"
          >
            <ElementButton
              :disabled="!hasModel3D"
              type="primary"
              plain
              class="detail-item__action-download"
            >
              Download {{ item.libraryType }}
            </ElementButton>
            <ElementDropdownMenu
              slot="dropdown"
              :append-to-body="false"
            >
              <ElementDropdownItem
                v-for="(file, index) in item.filesDict.model3D"
                :key="index"
                :command="{ url: file.assets_url, item, model3D: file }"
                class="download__item"
              >
                <span class="download__item-name">{{ file.description }}</span>
                |
                <span class="download__item-extension">{{
                  Utils.getExtensionFromFileName(file.assets_url)
                }}</span>
              </ElementDropdownItem>
            </ElementDropdownMenu>
          </ElementDropdown>
        </div>
      </div>

      <!-- Update item form -->
      <FormGenerator
        :show-form-generator="showLibraryItemCreateForm"
        :form-configuration="formConfiguration"
        :specific-step-id-to-edit="specificStepIdToEdit"
        :options="camelCasedFilters"
        :original-data="item"
        @form-close="closeUpdateForm"
        @form-complete="formComplete"
      />
    </template>
    <template v-else-if="isLoadingItem">
      <StitchLoader />
    </template>
    <LibraryDetailError
      v-if="errorStatus"
      :error-status="errorStatus"
      :active-library="activeLibraryType"
    />
  </StitchDialog>
</template>

<script>
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { UPLOAD_STATUS } from '@/constants/loadingStatus'
import Utils, { formatToLocaleDateLong } from '@/services/utils'
import { getFormConfig } from '@/services/formUtils'
import { IMAGE_RESOLUTION } from '@/constants/image'
import LibraryDetailError from '../LibraryDetailError'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'

// GetThumbnail, triggerDownload
import { DataUtils } from '@/mixins/utils.js'
import { BrowzwearPlugin } from '@/mixins/browzwearPlugin'

import { mapGetters, mapActions } from 'vuex'

import VueTypes from 'vue-types'

export default {
  name: 'DetailItem',

  components: {
    LibraryDetailError
  },

  mixins: [DataUtils, BrowzwearPlugin],

  props: {
    itemId: VueTypes.number
  },

  data () {
    return {
      LIBRARY_TYPE,
      Utils,
      specificStepIdToEdit: null,
      showDialog: false,
      itemPreviewUrl: null,
      showLibraryItemCreateForm: false,
      placeholder: require('../../../assets/images/img-placeholder.svg'),
      isLoadingItem: false,
      isFormDataUpdating: false,
      errorStatus: null
    }
  },

  computed: {
    ...mapGetters(['getItemDetail', 'getAvailableFilters']),

    ...mapGetters({
      activeLibraryType: 'getActiveLibraryType',
      itemUploadStatuses: 'getItemUploadStatuses'
    }),

    /**
     * @returns {object}
     */
    item () {
      return this.getItemDetail()
    },

    /**
     * @returns {string|null}
     */
    status () {
      if (this.itemId) {
        return this.itemUploadStatuses[this.itemId]
      } else {
        return null
      }
    },

    /**
     * @returns {boolean}
     */
    hasModel3D () {
      return this.item.filesDict && this.item.filesDict.model3D.length > 0
    },

    /**
     * @returns {boolean}
     */
    hasThumbnail () {
      return this.item.filesDict.thumbnail.length > 0
    },

    /**
     * @returns {boolean}
     */
    isTrim () {
      return this.item.libraryType === LIBRARY_TYPE.TRIM
    },

    /**
     * @returns {Array}
     */
    info () {
      // DIVISIONS
      const division = this.item.division && this.item.division.name
      const divisions =
        this.item.divisions &&
        this.item.divisions.map(division => division.name)
      // Composition
      const composition = this.item.composition
      // Fabric Material Subtype
      const fabricMaterialSubtype =
        this.item.fabricMaterialSubtype && this.item.fabricMaterialSubtype.name
      // Fabric Sustainability rating
      const sustainabilityRating =
        this.item.sustainabilityRating && this.item.sustainabilityRating.name
      const fabricWeight = this.item.weight
      // Trim Material subtype
      const trimMaterialSubtype =
        this.item.trimMaterialSubtype && this.item.trimMaterialSubtype.name
      // PLM Code
      const plmCode = this.item.plmCode
      // Season
      const season = this.item.season
      // Item creator
      const contributor = this.item.contributor
      // Block type (shape or block)
      const type = this.item.type

      let info = []

      if (division) {
        info.push(division)
      }

      if (divisions) {
        info = [...info, ...divisions]
      }

      if (composition) {
        info.push(composition)
      }

      if (fabricMaterialSubtype) {
        info.push(fabricMaterialSubtype)
      }

      if (sustainabilityRating) {
        info.push(`Sustainability: ${sustainabilityRating}`)
      }

      if (fabricWeight) {
        info.push(`Weight: ${fabricWeight} G/Sqm`)
      }

      if (trimMaterialSubtype) {
        info.push(trimMaterialSubtype)
      }

      if (plmCode) {
        info.push(`PLM code: ${plmCode}`)
      }

      if (season) {
        info.push(season)
      }

      if (this.item.libraryType === LIBRARY_TYPE.BLOCK && type) {
        info.push(`Block/Shape: ${type}`)
      }

      info.push(`Updated at: ${formatToLocaleDateLong(this.item.updatedAt)}`)

      if (contributor && contributor.email) {
        info.push(contributor.email)
      }

      return info
    },

    /**
     * @returns {Array}
     */
    availableFilters () {
      return this.getAvailableFilters()
    },

    /**
     * for matching the filter/keys in the item
     *
     * @returns {object}
     */
    camelCasedFilters () {
      const camelCasedFilter = {}

      Object.entries(this.availableFilters || {}).forEach(([key, value]) => {
        camelCasedFilter[Utils.convertSnakeCaseToCamelCase(key)] = value
      })

      return camelCasedFilter
    },

    /**
     * @returns {object}
     */
    formConfiguration () {
      return getFormConfig(this.item.libraryType)
    }
  },

  watch: {
    /**
     */
    status () {
      if (this.status) {
        let message = `${this.item.name} status: ${this.status}`
        let type = 'warning'

        const status = this.isFormDataUpdating ? 'updating' : 'uploading'

        if (this.status === UPLOAD_STATUS.PENDING) {
          message = `${this.item.name} ${status}`
          type = 'info'
        } else if (this.status === UPLOAD_STATUS.COMPLETED) {
          message = `${this.item.name} ${status} complete`
          type = 'success'
        } else if (this.status === UPLOAD_STATUS.FAILED) {
          message = `${this.item.name} ${status} failed`
          type = 'error'
        }

        this.$message({
          type,
          showClose: true,
          message
        })
      }
    },
    /**
     */
    item () {
      this.itemPreviewUrl = this.getThumbnail(
        this.item,
        IMAGE_RESOLUTION.PREVIEW_LIBRARY_ITEM
      )
    }
  },

  async created () {
    if (this.itemId !== null) {
      this.showDialog = true
      this.isLoadingItem = true

      try {
        await this.fetchItemDetail({
          itemId: this.itemId,
          libraryType: this.activeLibraryType
        })
      } catch (error) {
        if (error.response) {
          this.errorStatus = error.response.status
        } else {
          throw error
        }
      }

      this.isLoadingItem = false
    }
  },

  beforeDestroy () {
    this.showDialog = false
    this.isLoadingItem = false
    this.errorStatus = null
  },

  methods: {
    ...mapActions(['fetchItemDetail', 'clearItemDetail', 'deleteItem']),

    /**
     */
    closeUpdateForm () {
      this.showLibraryItemCreateForm = false
    },

    /**
     * @param {boolean} success
     */
    formComplete (success) {
      if (success) {
        this.closeUpdateForm()
      }
    },

    /**
     * @param {object} payload
     */
    sendItemToPlugin (payload) {
      const item = payload.item
      const index = payload.index
      this.sendDataToBrowzwear(item, index)

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.LIBRARY_3DFILE,
        action: TRACKER_EVENTS.SENT_TO_PLUGIN,
        label: '3D File',
        value: item.id,
        item
      })
    },

    /**
     * @param {object}        payload
     * @param {string}        payload.url         - Target url
     * @param {object}        payload.item        - ProcessedItem object
     * @param {string | null} [payload.assetType] - 3d File, thumbnail
     * @param {object | null} [payload.model3D]   - Object that brings the actual file being download when ProcessedItem has multiple 3D files
     */
    downloadAsset ({ url, item, assetType = '3D File', model3D = null }) {
      this.triggerDownload(url)

      const object =
        assetType === '3D File'
          ? TRACKER_OBJECTS.LIBRARY_3DFILE
          : TRACKER_OBJECTS.LIBRARY_IMAGE
      this.$tracking.trackEvent({
        object,
        action: TRACKER_EVENTS.DOWNLOADED,
        label: assetType,
        value: model3D || item,
        item
      })
    },

    /**
     * @param {Function} callback
     */
    triggerDropdownCallback (callback) {
      callback()
    },

    /**
     * @param   {boolean}  editOnlyModelFile
     *
     * @returns {Function}
     */
    toggleLibraryItemCreateForm (editOnlyModelFile) {
      return () => {
        if (editOnlyModelFile === true) {
          this.specificStepIdToEdit = 'model'
          this.isFormDataUpdating = false
        } else {
          this.specificStepIdToEdit = null
          this.isFormDataUpdating = true
        }

        this.showLibraryItemCreateForm = !this.showLibraryItemCreateForm
      }
    },

    /**
     * @param {object} payload
     */
    assignImageToThumbnail (payload) {
      this.$emit('assign-image-to-thumbnail', payload)
    },

    /**
     */
    openConfirmDelete () {
      const name = this.item.name

      this.$confirm(
        `This will permanently delete ${name}. Continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.confirmDelete(name)
        })
        .catch(() => {})
    },

    /**
     * @param {string} name
     */
    async confirmDelete (name) {
      await this.deleteItem({
        libraryType: this.activeLibraryType,
        id: this.item.id
      })

      this.showDialog = false
      this.onClose()

      this.$message({
        showClose: true,
        message: `${name} has been deleted`
      })
    },

    /**
     */
    onClose () {
      const newQueryUrl = JSON.parse(JSON.stringify(this.$route.query))
      delete newQueryUrl.detail
      this.clearItemDetail({ libraryType: this.activeLibraryType })

      this.$router.replace(
        {
          query: newQueryUrl
        },
        () => {}
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$dialog-width: 60vw;
$dialog-height: 70vh;
$dialog-margin-top: 10vh;

$image-preview-height: 200px;
$image-content-size: 28vh;

$dialog-header-height-1-line: 54px;
$dialog-header-height-2-line: 78px;

$dialog-body-height: calc(100% - #{$dialog-header-height-1-line});
$dialog-body-2-line-height: calc(100% - #{$dialog-header-height-2-line});
$dialog-body-detail-item-padding: 10px 10px 20px;

$dialog-footer-height: 40%;

$preview-3d-min-width: 80%;

.detail-item {
  /deep/ .el-dialog {
    width: $dialog-width;
    min-width: spacing(40);
    max-width: spacing(128);
    height: $dialog-height;
    max-height: none;
    margin-top: $dialog-margin-top;
    margin-bottom: 0;
    padding: 0;
    padding-top: spacing(2);
  }

  /deep/ .el-dialog__body {
    height: $dialog-body-height;
    padding: $dialog-body-detail-item-padding;
  }

  /deep/ .el-dialog__footer {
    padding: 0;
    box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  }
}

.detail-item__title {
  @include truncate-lines(2);

  padding: 0 spacing(5) spacing(1/2) spacing(5);
  overflow: hidden;
  color: $grey-dark;
  text-align: center;
  word-break: break-word;
}

.detail-item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.detail-item__content-image {
  position: relative;
  flex: 1;
  min-width: $preview-3d-min-width;
  min-height: 0;
  margin: 0 auto spacing(2);
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .content-image-fabric {
    max-height: initial;
    object-fit: cover;
  }
}

.detail-item__content-image--fabric-border {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-layer-2;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: spacing(1);
  border-image: url('~@/assets/images/fabric-border.png') 6% round;
  cursor: pointer;
  pointer-events: none;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      border-image: url('~@/assets/images/fabric-border.png') 7% repeat;
    }
  }
}

.detail-item__content-infos {
  @include flex-center;

  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: spacing(2);

  > * {
    margin: spacing(2) spacing(1/2) 0;
  }
}

.detail-item__content-actions {
  @include flex-center;

  flex-wrap: wrap;

  > * {
    margin-right: spacing(1);
    margin-bottom: 0;
    margin-left: spacing(1);
  }
}

.detail-item__content-actions-menu-item.detail-item__content-actions-menu-item-delete {
  color: $red;

  &:hover {
    color: $red;
  }
}

.download__item {
  display: flex;

  .download__item-name {
    margin-right: auto;
    padding-right: spacing(1);
  }

  .download__item-extension {
    width: spacing(5);
    margin-left: spacing(1);
    font-weight: $font-weight-medium;
    text-align: center;
  }
}
</style>
